@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@600&display=swap');

@font-face {
  font-family: 'Acumin-Pro-Condensed-Balck';
  src: url('./assets/Acumin-Pro-Condensed-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



body {
  font-family: 'Acumin-Pro-Condensed-Balck.ttf', sans-serif;
}

@media (max-width: 300px) {
  .contenedor > div {
    flex: 100%;}

  .foto {
    order: -1; /* Cambia el orden de la imagen para que aparezca antes en la columna */
    display: flexbox;
    object-fit: cover;  
    max-width: 80%;
    flex:0 0 auto;  
   
    
  }

  h1{
    text-align: left; /* Centra el texto a la derecha */
  }

  .texto {
    margin: 0; /* Elimina el margen lateral del contenedor de texto */
  
  }


  .text-container {
    margin: 0; /* Elimina el margen lateral del contenedor de texto */
  }

  .CSP{
   display: none;
  }
  .carousel-indicators {
    bottom: 10px !important;
  }
  
/* Contacto.css */
.contact-container {
  background-image: url('./componentes/Formulario-web-07.png'); /* Ruta a tu imagen */
  background-size: cover; /* Cubre todo el div */
  background-position: center; /* Centra la imagen en el div */
  margin-top: -5vh;
}

}

/* Media Query para pantallas más pequeñas */
@media (max-width: 768px) {
  .libro-container {
    width: 100%; /* Hacer que los libros se vean uno debajo del otro en pantallas pequeñas */
    
  }
  .redes{
    display:inline-flex; 
    align-items: center;
  }

  .x{
   
    
    height: 50%;
    }
    
   .f{
     
    
      height: 10px;
      
    }
  .instagram{
      margin: 0px;
      height: 50%;
    } 

    .CSP{
      display: none;
     }
       
}


/* Archivo: src/App.css */
@media (max-width: 768px) {
  .App-header {
    padding: 10px;
  }
  .navbar-nav {
    text-align: center;
  }
  .navbar-nav .nav-item {
    margin-bottom: 0px;
  }

  .carousel-indicators {
    bottom: 230px !important;
  }

  .carousel-item img {
    width: 30%; /* Asegura que las imágenes ocupen el 100% del contenedor */
    height: 800px; 
    object-fit: cover; /* Cambia a 'cover' para asegurar que las imágenes cubran el área del carrusel */
  }
  
}

h1{  
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14vh !important;
  margin-top: 10vh !important;
  margin-bottom: 30px !important;
  font-weight: bold;


}

h2, h3 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 10vh !important;  
  font-weight: bold; /* Negrita para destacar */
  margin-top: 5vh !important;
  /*margin-bottom: 30px !important;*/
}


.texto2{
  font-family: 'Acumin-Pro-Condensed-Balck',  sans-serif;
  flex:1;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 1%;  
  font-size: 4vh;  
}

.texto {
  font-family: 'Acumin-Pro-Condensed',  sans-serif;
  flex:1;
  text-align: left;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 5vh;
  font-size: 3vh;
  align-items: center; 
}

.texto3{
  font-family: 'Acumin-Pro-Condensed',  sans-serif;
  font-size: 15px;
  text-align: center;
}


/* Estilos del encabezado */
.App-header {
  background-image: '../src/componentes/textura.jpg';
  background-color: #ffffff; /*Color de fondo oscuro */
  color: #767676; /* Texto gris */
  text-align: center; /* Alinear el texto al centro */
    
}



/* Contacto.css */
.contact-container {
  background-image: url('./componentes/Formulario-web-07.png'); /* Ruta a tu imagen */
  background-size: cover; /* Cubre todo el div */
  background-position: center; /* Centra la imagen en el div */
  margin-top: -3vh;
}



.fpg_logo {
  object-fit: cover;
  /*display: flexbox;*/ 
  max-width: 100%; 
  align-items: center; /* Centrar verticalmente los elementos */
  justify-content: center; /* Centrar horizontalmente los elementos */
  text-align: center; /* Alinear el texto al centro */
  background-color: #fff;
  background-size: cover; /* Cubre todo el div */
}

.fpg {
  align-items: center;
  display:inline-flexbox;
}

.fpg-content {
  display: flex;
  align-items: center; /* Centra verticalmente los elementos hijos */
}

.Coti4{ 
   
  background-size: cover; /* Cubre todo el div */
  background-position: center; /* Centra la imagen en el div */
 }

.Coti5{
  width:100%;
  background-size: cover; /* Cubre todo el div */
}

.libro{
  width:20%;
  display:list-item;
}

.foto {
  display: block;
  object-fit: cover;  
  max-width: 100%;
  flex: 0 0 auto;
  width:  100%; /* Aumenta el ancho para hacer zoom */
  height: 800px; /* Define una altura específica para mostrar solo una parte de la imagen */
  overflow: hidden; /* Oculta las partes de la imagen que exceden el contenedor */
  position: relative; /* Necesario para usar object-position */
  object-position: right; /* Ajusta la posición de la imagen dentro del contenedor */
}


.text-container {
  flex: 1; /* El contenedor de texto se expande para llenar el espacio disponible */
  margin: 0 20px; /* Margen a los lados del texto */
}

.content {
  align-items: center; /* Centra verticalmente los elementos */
  display: flexbox;
  flex-wrap: wrap;
  flex-direction: row-reverse; /* Cambia el orden de los elementos */
  
}

.content > div {
  flex:50%
}

.img-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

/*NAV BAR*/
/* Estilos para la barra de navegación */
.navbar {
  
  background-color: rgba(0, 0, 0, 0.50); /* Fondo con 50% de opacidad */
}

/* Estilos para los enlaces de la navbar */
.navbar .nav-link {
  
  color: rgb(213, 213, 213); /* Color del texto de los enlaces */
  font-weight: bold; /* Hace que el texto de los enlaces sea negrita */
}

/* Estilo para el dropdown */
.dropdown-item, .dropdown-menu.show {
  
  background-color: rgba(77, 77, 77, 0.50); /* Fondo del dropdown con opacidad */
}

.navbar-nav .nav-item .dropdown-menu .dropdown-item, .publicaciones-dropdown
.navbar-nav .nav-item .dropdown-menu .dropdown-item{
  
  font-weight: bold; /* Hace que el texto de los enlaces sea negrita */
  color: rgb(213, 213, 213);
}

/* Cambios de estilo al pasar el mouse sobre los enlaces y elementos del dropdown */
.navbar .nav-link:hover, .dropdown-item:hover, .navbar-brand:hover {
  
  background-color: rgba(141, 141, 141, 0.50);/* Cambia el color del texto al pasar el mouse */
  color: rgb(213, 213, 213);
}

/* Asegúrate de que los submenús también se vean bien al pasar el mouse */
.navbar-nav .nav-item .dropdown-menu .dropdown-item:hover,
.navbar-nav .nav-item .dropdown-menu .dropdown-item a:hover {
  background-color: rgb(255, 255, 255);
 /* background-color: rgba(141, 141, 141, 0.50); /* Fondo del dropdown-item al pasar el mouse */
  color: rgb(63, 63, 63) !important; /* Cambia el color del texto del dropdown-item al pasar el mouse */
  font-weight: bold; /* Hace que el texto de los enlaces sea negrita */
}

.nav-dropdown-custom.nav-item.dropend:hover{
  background-color: #fff;
  color: rgb(63, 63, 63);
}


/* Asegúrate de que los enlaces dentro de los dropdown también se vean bien */
.navbar-nav .nav-item .dropdown-menu a:hover {
  color: rgb(63, 63, 63);
  background-color: rgb(255, 255, 255);
  /*color:  rgba(77, 77, 77) !important;  Cambia el color del texto del enlace del dropdown-item al pasar el mouse */
}

/*FIN NAVBAR*/
.CSP{
 
  margin-right: 100vh;
  height: 80px;
}

/*LIBRO*/

.libro.img-fluid{
  width: 300px;
}
.libros-container {
  padding: 20px;
}

.libros-wrapper {
  margin-bottom: 20px;
}

.libro-container {
  display: flex;
  align-items: center;
}

.libro {
  max-width: 100%;
  height: auto;
}

.libro-text {
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  margin-left: 20px;
}

/*FOOTER*/
.footer {
  background-color: #4494e3;
  padding: 35px 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
}

.redes{
  margin-right: 50px;
}
.x{
  max-width: 30px;
  display: inline-flex;
  margin:8px
}

.f{
  height: 35px;
  margin: 8px;
  
}
.instagram{
  margin: 8px;
}

/* Estilos Slider */

.slider2-container {
  width: 100%; /* Ancho específico para el contenedor del slider */
  margin: 0 auto;  /*Centrar el contenedor en la página */
  height: 100%;
}

.slider2-image {
  width: 100%; /* Hacer que la imagen ocupe todo el ancho del contenedor */
  height: 30%; /* Mantener la relación de aspecto */
}

.carousel {
  width: 100%;
  margin: auto;
}

.carousel-item img {
  width: 100%; /* Asegura que las imágenes ocupen el 100% del contenedor */
  height: 450px; 
  object-fit: cover; /* Cambia a 'cover' para asegurar que las imágenes cubran el área del carrusel */
}

/* Indicadores */
.carousel-indicators {
  bottom: 25px !important;
  
}

.carousel-indicators [data-bs-target] {
  background-color: #fff !important; 
  border: 1px solid #8c8a8a !important; 
  width: 10px !important; 
  height: 10px !important;
  margin: 0 10px !important; 
  border-radius: 50% !important;
}

.carousel-indicators .active {
  background-color: rgb(66, 66, 68) !important; 
}

/* Flechas de navegación */
.carousel-control-prev,
.carousel-control-next {
  width: 50px !important; 
  height: 50px !important; 
  background-color: #8c8a8a !important; 
  border-radius: 50% !important; 
  top: 50% !important;
  transform: translateY(-50%) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff !important;
  margin: 20px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 20px 20px !important; 
  background-color: transparent !important; 
}

/* Transición */
.carousel-inner {
  transition: transform 1s ease-in-out !important; 
}

/* Recuadro de texto */
.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 /* background: rgba(0, 0, 0, 0.5); */
  color: #fff;
  padding: 0px; 
  text-align: left; 
}

.carousel-caption h3,
.carousel-caption p {
  width: 100%;
}